import { RouteRecordRaw } from "vue-router"

/** Define all existing pages https://router.vuejs.org/guide/ **/
export default [
    //GP PART
    {
        path: "/",
        component: async () => await import("./components/pages/welcome.vue"),
        children: []
    },
    {
        path: "/dashboard",
        component: async () => await import("./components/pages/dashboard.vue"),
        children: [
            {
                path: "",
                component: async () => await import("./components/pages/dashboard/entry.vue")
            },
            {
                path: "me",
                component: async () => await import("./components/pages/administration/me.vue"),
                children: [
                    {
                        path: "",
                        component: async () => await import("./components/pages/administration/me/profile.vue"),
                    },
                    {
                        path: "account",
                        component: async () => await import("./components/pages/administration/me/account.vue"),
                    },
                    {
                        path: "notification",
                        component: async () => await import("./components/pages/administration/me/notification.vue"),
                    }
                ]
            },
            {
                path: "users",
                component: async () => await import("./components/pages/administration/users/manage.vue"),
            },
            {
                path: "absence",
                component: async () => await import("./components/pages/services/absence/absence.vue")
            },
            {
                path: "licensemanager",
                component: async () => await import("./components/pages/services/licenseManager/licenseManager.vue"),
                children :[
                    {
                        path: "whitelist",
                        component: async () => await import("./components/pages/services/licenseManager/whitelist.vue")
                    },
                    {
                        path: "licensepack",
                        component: async () => await import("./components/pages/services/licenseManager/licensePack.vue")
                    }
                ]
            },
            {
                path: "publication",
                component : async () => await import("./components/pages/dashboard/publication/publication.vue"),
                children: [
                    {
                        path: ":idPublication",
                        component: async () => await import("./components/pages/dashboard/publication/publicationElement.vue"),
                    }
                ]
            },
            {
                path: "software",
                component: async () => await import("./components/pages/services/software/moduleManager.vue"),
                children :[
                    {
                        path: "modules",
                        component: async () => await import("./components/pages/services/software/modules.vue")
                    },
                    {
                        path: "softwareCorrection",
                        component: async () => await import("./components/pages/services/software/softwareCorrection.vue")
                    },
                    {
                        path: "deployment",
                        component: async () => await import("./components/pages/empty.vue"),
                        children: [
                            {
                                path: '',
                                component: async () => await import("./components/pages/services/software/deployment.vue"),
                            },
                            {
                                path: ':idProposal',
                                component: async () => await import("./components/pages/empty.vue"),
                                children : [
                                    { 
                                        path: '',
                                        component: async () => await import("./components/pages/services/software/deployment/overview.vue"),
                                    },
                                    {
                                        path: 'test/:idTest',
                                        component: async () => await import("./components/pages/services/software/deployment/deploymentTest.vue"),
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: "tests",
                        component: async () => await import("./components/pages/empty.vue"),
                        children: [
                            {
                                path: '',
                                component: async () => await import("./components/pages/services/software/tests/overview.vue"),
                            },
                            {
                                path: ':idTest',
                                component: async () => await import("./components/pages/services/software/tests/editTest.vue"),
                            },
                        ]
                    }
                ]
            },
            {
                path: "usermanager",
                component: async () => await import("./components/pages/services/userManager/userManager.vue"),
                children :[
                    {
                        path: "users",
                        component: async () => await import("./components/pages/services/userManager/users.vue")
                    }
                ]
            }
        ]
    },
    {
        path: "/login",
        component: async () => await import("./components/pages/login.vue"),
        children: []
    },
    {
        path: "/logout",
        component: async () => await import("./components/pages/logout.vue"),
        children: []
    },

    //Default route if page not found
    {
        path: "/:pathMatch(.*)*",
        redirect: "/",
    }
] satisfies RouteRecordRaw[]