import { createRouter, createWebHistory, type Router  } from "vue-router";

//Import all defined routes
import routes from "./routes"

let router : Router = (window as any).router;
/** Generate route system **/
if (!router){
    router = createRouter({
        history: createWebHistory(), //@TODO REMOVE WARNING ( TO WORK WITH ELECTRON ) : createMemoryHistory
        routes,
    });

    (window as any).router = router;
}

/** Initialisation of the web browser, load last path **/

export async function moveUrl(path, query){
    const toPush = { path, query };
    await router.push(toPush);
}

export async function moveUrlName(name, params){
    const toPush = { name, params };
    await router.push(toPush);
}

export async function refreshCurrentPage(){
    await router.go(0);
}

export function getCurrentQuery() {
    return router.currentRoute.value.query;
}

export function getCurrentParams(){
    return router.currentRoute.value.params;
}

export async function MoveHistory(index = 0) {
    await router.go(index);
}

export default router;